import * as React from 'react';

import Layout from "../components/Layout";
import { Seo } from '../components/Seo';

const NotFoundPage = () => (
  <Layout meta={{socialTitle: "Page Not Found"}} styles={{bannerColor:"transparent"}}>
    <div className="hero has-text-centered py-6">
      <h1 className="title is-1">404: Not found</h1>
    </div>
  </Layout>
);

export default NotFoundPage;

export const Head = ({ location, params, data, pageContext }) => {
  return(
    <Seo title="Billy Graham Evangelistic Association | Page Not Found" description="Your gift today will share the Good News with others and point them to the love of our Heavenly Father." pathname={location.pathname} />
  )
}